import React, {useState} from "react";
import Footer from "../../components/Footer/Footer";

import ForInvestorsCircle from '../../images/ForInvestors/for-partners-circles.png'
import ForInvestorsPalm from '../../images/ForInvestors/for-investors-palm.png'
import ForInvestorsRhombus from '../../images/ForInvestors/for-investors-rhombus.png'
import ArrowLeft from '../../images/ForInvestors/for-investors-arrow-left.png'
import ArrowRight from '../../images/ForInvestors/for-investors-arrow-right.png'
import TextImg from '../../images/ForInvestors/for-investors-text-img.png'
import * as styles from './styles.module.scss'
import FadeInSection from "../../components/FadeInSection/FadeInSection";
import {scrollToTop} from "../../helpers/ScrollTop";

import {forInvestorSwiperDb} from "../../DB/DB";
import  {Navigation, Pagination, Scrollbar, A11y, Mousewheel, Keyboard, EffectCreative} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/mousewheel';
import 'swiper/css/effect-creative';
import './style.css'
import ScrollTopBtn from "../../components/UI/ScrollTopBtn";
import {Link} from "gatsby";
import ForInvestorsHouse from "../../assets/animateImg/ForInvestors/ForInvestorsHouse/ForInvestorsHouse";
import ForInvestorsCircles from "../../assets/animateImg/ForInvestors/ForInvestorCircles/ForInvestorsCircles";
import RhombusFigure from "../../assets/animateImg/ForInvestors/RhombusFigure/RhombusFigure";
import Palm from "../../assets/animateImg/ForInvestors/Palm/Palm";
import ForInvestorMain from "../../assets/animateImg/ForInvestors/ForInvestorMain/ForInvestorMain";


const ForInvestors = () => {
    const handleClick = () => {
        scrollToTop()
    }
    const [currentSlideIndex, setCurrentSlideIndex] = useState(1)
    return (
        <div>
            <ScrollTopBtn/>
            <section className={styles.for_investors_hero_container}>
                <div className={styles.for_investors_hero}>
                    <FadeInSection>
                        <div className={styles.for_investors_header_container}>
                            <div className={styles.hero_text_block}>
                                <h1>THIS IS AR</h1>
                                <p>It was in fantastic films, but now it's real and we are doing it. We created the Unisphere metaverse and made it free from the physical reality limits.</p>
                            </div>
                            <div className={styles.hero_img}>
                                <ForInvestorMain/>
                            </div >
                            <div className={styles.hero_img_mobile}></div>
                        </div>
                    </FadeInSection>
                </div>
                <div className={styles.scroll_to_explore}>
                    <FadeInSection>
                        <h5>SCROLL TO EXPLORE</h5>
                    </FadeInSection>
                </div>
            </section>
            <section className={styles.for_investors_main}>
                <div className={styles.for_investors_info}>
                    <FadeInSection>
                        <div className={`${styles.for_investors_info_block} ${styles.for_investors_column_reverse}`}>
                            <div className={styles.for_investors_info_img_block_left}>
                                <div className={styles.for_investors_info_img_wrapper}>
                                    <ForInvestorsHouse/>
                                </div>
                            </div>
                            <div className={styles.for_investors_info_text}>
                                <h3>combination of virtual and real</h3>
                                <p>Unisphere is the metaverse that combines virtuality and reality. We help people to
                                    add 3D
                                    objects to the real world and interact with them.</p>
                            </div>
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className={styles.for_investors_info_block}>
                            <div className={styles.for_investors_info_text}>
                                <h3>integration with different apps</h3>
                                <p>The metaverse ecosystem integrates with different apps.
                                    Unisphere is now available via the New Layer app. It is the universal browser to
                                    create
                                    and modify the metaverse.</p>
                                <a target='_blank' href="https://apps.apple.com/us/app/id1559419724">
                                    <button className={styles.for_investors_button}>Download the New Layer App →
                                    </button>
                                </a>
                            </div>
                            <div className={styles.for_investors_info_img_block_right}>
                                <div className={styles.for_investors_info_img_wrapper}>
                                    <ForInvestorsCircles/>
                                </div>
                            </div>
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className={styles.for_investors_info_coming_soon}>
                            <h3>Comming soon</h3>
                            <p>Soon Unisphere will be integrated with a time-hunter game and a 3D scanner.</p>
                            <div className={styles.for_patners_coming_soon_img_wrapper}>
                                <div className={styles.for_investors_palm}>
                                    <Palm/>
                                </div>
                            </div>
                            <div className={styles.for_patners_coming_soon_img_wrapper}>
                                <div className={styles.for_investors_rhombus}>
                                    <RhombusFigure/>
                                </div>
                            </div>
                        </div>
                    </FadeInSection>
                </div>
            </section>
            <section className={styles.for_investors_how_to_use}>
                <h2>How to use Unisphere?</h2>
                <Swiper
                    onSlideChange={(e) => setCurrentSlideIndex(e.realIndex + 1)}
                    modules={[Pagination, Navigation, Scrollbar, A11y, Mousewheel, Keyboard, EffectCreative]}
                    speed={900}
                    keyboard={true}
                    loop={true}
                    spaceBetween={50}
                    mousewheel={true}
                    navigation={{
                        nextEl: ".arrowRight",
                        prevEl: ".arrowLeft",
                    }}
                    creativeEffect={{
                        prev: {
                            opacity: 0,
                            translate: {}
                        },
                        next: {
                            translate: ['100%', 0, 0],
                        }
                    }
                    }
                    effect='creative'

                >
                    <div>
                        {forInvestorSwiperDb.map(el =>
                            <SwiperSlide>
                                <div key={el.id} className={styles.for_investors_how_to_use_info}>
                                    <div className={styles.for_investors_how_to_use_img_wrapper}>
                                        <img src={el.img} alt=""/>
                                    </div>
                                    <div className={styles.for_investors_how_to_use_navigation}>
                                        <div className={styles.for_investors_how_to_use_navigation_icon_wrapper}>
                                            <img src={el.icon} alt=""/>
                                        </div>
                                        <h4>{el.title}</h4>
                                        <p>{el.subtitle}</p>
                                        <div className={styles.for_investors_how_to_use_navigation_wrapper}>
                                            <p className={styles.for_investors_how_to_use_navigation_pages}>
                                                <span>{currentSlideIndex}</span> / 10
                                            </p>
                                            <div className={styles.for_investors_how_to_use_navigation_arrows}>
                                                <div className='arrowLeft'>
                                                    <div
                                                        className={`${styles.for_investors_how_to_use_navigation_arrow}`}>
                                                        <img src={ArrowLeft} alt=""/>
                                                    </div>
                                                </div>
                                                <div className='arrowRight'>
                                                    <div
                                                        className={`${styles.for_investors_how_to_use_navigation_arrow}`}>
                                                        <img src={ArrowRight} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                    </div>
                </Swiper>
            </section>
            <FadeInSection>
                <section className={styles.for_investors_pre_footer}>
                    <div className={styles.for_investors_pre_footer_title}>
                        <div className={styles.for_investors_pre_footer_title_wrapper}>
                            <img src={TextImg} alt=""/>
                        </div>
                    </div>
                    <div className={styles.for_investors_pre_footer_info}>
                        <h3>Want to invest and lead the AR metaverse market with us?</h3>
                        <p>Schedule a meeting <a href="https://calendly.com/ar-generation/unisphere-pitch?month=2022-09">by the link</a> so we tell you more about the project.</p>
                        <p>Also, you can write us in the <Link onClick={handleClick} to="/contacts">Contacts.</Link></p>
                    </div>
                </section>
            </FadeInSection>
            <Footer/>
        </div>

    )
}
export default ForInvestors