import React from 'react';
import * as styles from "./styles.module.scss";
import Cartoon from "./Cartoon";
import Roof from "./Roof";
import BodyBottom from "./BodyBottom";
import BodyTop from "./BodyTop";
import circle from "../img/circle.png"

const ForInvestorsHouse = () => {
    return (
        <div className={styles.forInvestors_house_container}>
            <div className={styles.cartoon_block}>
                <Cartoon/>
            </div>
            <div className={styles.roof_block}>
                <Roof/>
            </div>
            <div className={styles.bodyTop_block}>
                <BodyTop/>
            </div>
            <div className={styles.bodyBottom_block}>
                <BodyBottom/>
            </div>
            <div className={styles.circle_block}>
                <img src={circle} alt="circle"/>
            </div>
        </div>
    );
};

export default ForInvestorsHouse;


