import React, {useEffect} from 'react';

import * as styles from "./styles.module.scss";
import Parallax from "parallax-js";

import cartoon from "../img/cartoon.png"
import dashed from "../../dashedImg/azureBg.png"

const Cartoon = () => {
    useEffect(() => {
        const cartoon_scene = document.getElementById("cartoon_scene");
        new Parallax(cartoon_scene);
    }, []);

    return (
        <div className={styles.cartoon_container}>
            <img src={cartoon} alt=""/>
            <div id='cartoon_scene' className={styles.polygon}>
                <img className={styles.dashed} data-depth={2} src={dashed} alt=""/>
            </div>
        </div>
    );
};

export default Cartoon;



