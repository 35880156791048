// extracted by mini-css-extract-plugin
export var bodyBottom_block = "styles-module--bodyBottom_block--IN+2o";
export var bodyBottom_container = "styles-module--bodyBottom_container--Cy2L0";
export var bodyTop_block = "styles-module--bodyTop_block--wvE2k";
export var cartoon_block = "styles-module--cartoon_block--5z0Hm";
export var cartoon_container = "styles-module--cartoon_container--pw-wt";
export var circle_block = "styles-module--circle_block--yrqNq";
export var dashed = "styles-module--dashed--McskY";
export var forInvestors_house_container = "styles-module--forInvestors_house_container--svehq";
export var polygon = "styles-module--polygon--ryzc4";
export var roof_block = "styles-module--roof_block--gCPuD";
export var roof_container = "styles-module--roof_container--EIQAA";