import React from 'react';
import * as styles from "./styles.module.scss";
import UCircle from "./UCircle";
import CubeCircle from "./CubeCircle";
import NCircle from "./NCircle";
import cube from "../img/cube.png"


const ForInvestorsCircles = () => {
    return (
        <div className={styles.forInvestors_circles_container}>
            <div className={styles.UCircle_block}>
                <UCircle/>
            </div>
            <div className={styles.CubeCircle_block}>
                <CubeCircle/>

            </div>
            <img className={styles.cube_img} src={cube} alt="cube"/>
            <div className={styles.NCircle_block}>
                <NCircle/>
            </div>
        </div>
    );
};

export default ForInvestorsCircles;