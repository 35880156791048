import React, {useEffect} from 'react';

import * as styles from "./styles.module.scss";
import Parallax from "parallax-js";

import nCircle from "../img/nCircle.png"
import dashed from "../../dashedImg/mainBlue.png"

const NCircle = () => {
    useEffect(() => {
        const n_circle_scene = document.getElementById("n_circle_scene");
        new Parallax(n_circle_scene);
    }, []);

    return (
        <div className={styles.NCircle_container}>
            <img src={nCircle} alt=""/>
            <div id='n_circle_scene' className={styles.polygon}>
                <img className={styles.dashed} data-depth={2} src={dashed} alt=""/>
            </div>
        </div>
    );
};

export default NCircle;



