import React, {useEffect} from 'react';

import * as styles from "./styles.module.scss";
import Parallax from "parallax-js";

import legs from "../img/legs.png";
import hand from "../img/hand.png";
import face from "../img/face.png";
import whiteDashed from "../../dashedImg/whiteBg.png";
import blueDashed from "../../dashedImg/azureBg.png"

const ForInvestorManLegs = () => {
    useEffect(() => {
        const legs_scene = document.getElementById("legs_scene");
        new Parallax(legs_scene);
    }, []);

    return (
        <div className={styles.manLegs_container}>
            <img src={legs} alt=""/>
            <div id='legs_scene' className={styles.polygon}>
                <img className={styles.dashed} data-depth={2} src={blueDashed} alt=""/>
            </div>
        </div>
    );
}

const ForInvestorManHand = () => {
    useEffect(() => {
        const hand_scene = document.getElementById("hand_scene");
        new Parallax(hand_scene);
    }, []);

    return (
        <div className={styles.manHand_container}>
            <img src={hand} alt=""/>
            <div id='hand_scene' className={styles.polygon}>
                <img className={styles.dashed} data-depth={2} src={whiteDashed} alt=""/>
            </div>
        </div>
    );
}

const ForInvestorManFace = () => {
    useEffect(() => {
        const face_scene = document.getElementById("face_scene");
        new Parallax(face_scene);
    }, []);

    return (
        <div className={styles.manFace_container}>
            <img src={face} alt=""/>
            <div id='face_scene' className={styles.polygon}>
                <img className={styles.dashed} data-depth={2} src={whiteDashed} alt=""/>
            </div>
        </div>
    );
}


const ForInvestorMan = () => {
    return (
        <div className={styles.forInvestorMan_container}>
            <div className={styles.man_hand_block}>
                <ForInvestorManHand/>
            </div>
            <div className={styles.man_legs_block}>
                <ForInvestorManLegs/>
            </div>
            <div className={styles.man_face_block}>
                <ForInvestorManFace/>
            </div>
        </div>
    );
};

export default ForInvestorMan;
