// extracted by mini-css-extract-plugin
export var dashed = "styles-module--dashed--q2U4Y";
export var forInvestorMain_bgLine = "styles-module--forInvestorMain_bgLine--lwNM4";
export var forInvestorMain_container = "styles-module--forInvestorMain_container--Aend+";
export var forInvestorMain_island = "styles-module--forInvestorMain_island--i0hEZ";
export var forInvestorMain_man = "styles-module--forInvestorMain_man--S8x3y";
export var forInvestorMain_planet = "styles-module--forInvestorMain_planet--TylJp";
export var forInvestorMan_container = "styles-module--forInvestorMan_container--s59P5";
export var islandFlor_block = "styles-module--islandFlor_block--JcnS6";
export var islandFlor_container = "styles-module--islandFlor_container--gTuc8";
export var islandTree_block = "styles-module--islandTree_block--KrHND";
export var islandTree_container = "styles-module--islandTree_container--T7x1C";
export var island_container = "styles-module--island_container--8rof9";
export var manFace_container = "styles-module--manFace_container--2Yb1Y";
export var manHand_container = "styles-module--manHand_container--y20Gq";
export var manLegs_container = "styles-module--manLegs_container--TPlx3";
export var man_face_block = "styles-module--man_face_block--fgDS1";
export var man_hand_block = "styles-module--man_hand_block--kYth-";
export var man_legs_block = "styles-module--man_legs_block--gcAZo";
export var planet_block = "styles-module--planet_block--OXaYB";
export var planet_container = "styles-module--planet_container--gZG0+";
export var planet_line = "styles-module--planet_line--5IUYN";
export var polygon = "styles-module--polygon---GXWx";
export var polygon_big = "styles-module--polygon_big--GIxxg";
export var polygon_small = "styles-module--polygon_small--rALLX";