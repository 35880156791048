// extracted by mini-css-extract-plugin
export var for_investors_button = "styles-module--for_investors_button--88jta";
export var for_investors_column_reverse = "styles-module--for_investors_column_reverse--gClxq";
export var for_investors_header_container = "styles-module--for_investors_header_container--V-Rea";
export var for_investors_hero = "styles-module--for_investors_hero--LMz-p";
export var for_investors_hero_container = "styles-module--for_investors_hero_container--kGoje";
export var for_investors_how_to_use = "styles-module--for_investors_how_to_use--499Qz";
export var for_investors_how_to_use_img_wrapper = "styles-module--for_investors_how_to_use_img_wrapper--jyWdK";
export var for_investors_how_to_use_info = "styles-module--for_investors_how_to_use_info--knIs1";
export var for_investors_how_to_use_navigation = "styles-module--for_investors_how_to_use_navigation--08E2k";
export var for_investors_how_to_use_navigation_arrow = "styles-module--for_investors_how_to_use_navigation_arrow--e7FIn";
export var for_investors_how_to_use_navigation_arrows = "styles-module--for_investors_how_to_use_navigation_arrows--LaeCG";
export var for_investors_how_to_use_navigation_icon_wrapper = "styles-module--for_investors_how_to_use_navigation_icon_wrapper--KA4jf";
export var for_investors_how_to_use_navigation_pages = "styles-module--for_investors_how_to_use_navigation_pages--+L0jb";
export var for_investors_how_to_use_navigation_wrapper = "styles-module--for_investors_how_to_use_navigation_wrapper--S2qez";
export var for_investors_info = "styles-module--for_investors_info--rs+L2";
export var for_investors_info_block = "styles-module--for_investors_info_block--F5B33";
export var for_investors_info_coming_soon = "styles-module--for_investors_info_coming_soon--cWzQK";
export var for_investors_info_img_block_left = "styles-module--for_investors_info_img_block_left--anzKp";
export var for_investors_info_img_block_right = "styles-module--for_investors_info_img_block_right--wq4p9";
export var for_investors_info_img_wrapper = "styles-module--for_investors_info_img_wrapper--obQrA";
export var for_investors_info_text = "styles-module--for_investors_info_text--uFMyF";
export var for_investors_main = "styles-module--for_investors_main--WBWDa";
export var for_investors_palm = "styles-module--for_investors_palm---Wxge";
export var for_investors_pre_footer = "styles-module--for_investors_pre_footer---J4hg";
export var for_investors_pre_footer_info = "styles-module--for_investors_pre_footer_info--jt72G";
export var for_investors_pre_footer_title = "styles-module--for_investors_pre_footer_title--hWQT0";
export var for_investors_pre_footer_title_wrapper = "styles-module--for_investors_pre_footer_title_wrapper--u3jZk";
export var for_investors_rhombus = "styles-module--for_investors_rhombus--oZF9+";
export var for_patners_coming_soon_img_wrapper = "styles-module--for_patners_coming_soon_img_wrapper--pqaTU";
export var hero_img = "styles-module--hero_img--vUBsY";
export var hero_img_mobile = "styles-module--hero_img_mobile--Iq+CT";
export var hero_text_block = "styles-module--hero_text_block--EALkw";
export var scroll_to_explore = "styles-module--scroll_to_explore--suPJF";