import React from 'react';
import * as styles from "./styles.module.scss";
import ForInvestorMan from "./ForInvestorMan";
import ForInvestorPlanet from "./ForInvestorPlanet";
import ForInvestorIsland from "./ForInvestorsIsland";
import bgLine from "../img/main_line.png"

const ForInvestorMain = () => {
    return (
        <div>
            <div className={styles.forInvestorMain_container}>
                <div className={styles.forInvestorMain_man}>
                    <ForInvestorMan/>
                </div>
                <div className={styles.forInvestorMain_planet}>
                    <ForInvestorPlanet/>
                </div>
                <div className={styles.forInvestorMain_island}>
                    <ForInvestorIsland/>
                </div>
                <img className={styles.forInvestorMain_bgLine} src={bgLine} alt=""/>
            </div>
        </div>
    );
};

export default ForInvestorMain;