import React, {useEffect} from 'react';

import * as styles from "./styles.module.scss";
import Parallax from "parallax-js";
import tree from "../img/tree.png"
import islandFlor from "../img/islandFlor.png";
import dashedWhite from "../../dashedImg/whiteBg.png";
import dashedDark from "../../dashedImg/darkBlueBg.png"

const IslandFlor = () => {
    useEffect(() => {
        const island_flor_scene = document.getElementById("island_flor_scene");
        new Parallax(island_flor_scene);
    }, []);

    return (
        <div className={styles.islandFlor_container}>
            <img src={islandFlor} alt=""/>
            <div id='island_flor_scene' className={styles.polygon}>
                <img className={styles.dashed} data-depth={2} src={dashedWhite} alt=""/>
            </div>
        </div>
    )
}

const IslandTree = () => {
    useEffect(() => {
        const tree_big_scene = document.getElementById("tree_big_scene");
        const tree_small_scene = document.getElementById("tree_small_scene");
        new Parallax(tree_big_scene);
        new Parallax(tree_small_scene);
    }, []);

    return (
        <div className={styles.islandTree_container}>
            <img src={tree} alt=""/>
            <div id='tree_big_scene' className={styles.polygon_big}>
                <img className={styles.dashed} data-depth={2} src={dashedDark} alt=""/>
            </div>
            <div id='tree_small_scene' className={styles.polygon_small}>
                <img className={styles.dashed} data-depth={2} src={dashedDark} alt=""/>
            </div>
        </div>
    )
}

const ForInvestorIsland = () => {

    return (
        <div className={styles.island_container}>
           <div className={styles.islandFlor_block}>
               <IslandFlor/>
           </div>
            <div className={styles.islandTree_block}>
                <IslandTree/>
            </div>
        </div>
    );
}


export default ForInvestorIsland