import Img1 from '../images/ForInvestors/img1.png'
import Img2 from '../images/ForInvestors/img2.png'
import Img3 from '../images/ForInvestors/img3.png'
import Img4 from '../images/ForInvestors/img4.png'
import Img5 from '../images/ForInvestors/img5.png'
import Img6 from '../images/ForInvestors/img6.png'
import Img7 from '../images/ForInvestors/img7.png'
import Img8 from '../images/ForInvestors/img8.png'
import Img9 from '../images/ForInvestors/img9.png'
import Img10 from '../images/ForInvestors/img10.png'

export const forInvestorSwiperDb = [
    {
        id:1, img: Img1, icon:'https://i.ibb.co/dL7vL5n/icon01.png', title:'AR ADVERTISING', subtitle:'Advertise brands in AR space without bureaucracy. '
    },
    {
        id:2, img: Img2, title:'PRODUCTS DEMONSTRATION', subtitle:'Show products to your clients using AR.'
    },
    {
        id:3, img:Img3, icon:'https://i.ibb.co/Jk2FmXP/icon03.png', title:'NFT MARKETPLACE', subtitle:'Buy, sell and demonstrate NFT 2D/3D art in Unisphere.'
    },
    {
        id:4, img:Img4, icon:'https://i.ibb.co/Tgvn9R2/icon04.png', title:'AR EXHIBITIONS', subtitle:'Hold exhibitions in AR and demonstrate art all over the world.'
    },
    {
        id:5, img:Img5, icon:'https://i.ibb.co/C6c9LvY/icon05.png', title:'IN/OUTDOOR NAVIGATION', subtitle:'Place AR navigation in buildings and on streets to make them more comfortable.'
    },
    {
        id:6, img: Img6, icon:'https://i.ibb.co/pfLd0SW/icon06.png', title:'ENGAGING EDUCATION', subtitle:'Teach and learn subjects with 3D demonstration.'
    },
    {
        id:7, img: Img7, icon:'https://i.ibb.co/SyNHx21/icon07.png', title:'COMFORTABLE WORK ', subtitle:'Use 3D objects and AR technologies for work tasks.'
    },
    {
        id:8, img: Img8, icon:'https://i.ibb.co/StjxBSs/icon08.png', title:'AR GAMES', subtitle:'Create and play with friends in AR games and geo quests.'
    },
    {
        id:9, img:Img9, icon:'https://i.ibb.co/pWLdkhR/icon09.png', title:'AVAILABLE CONTENT', subtitle:'Place Metascreens with essential information in any place you need.'
    },
    {
        id:10, img:Img10, icon:'https://i.ibb.co/XJKKHrp/icon10.png', title:'AR ALL OVER THE WORLD', subtitle:''
    },
]

export const Events = []

// {
//     eventImg: 'https://fs39.fex.net/preview/4135803812/0x0',
//         eventPlace: '950 Fulton St, Brooklyn, NY 11238, USA',
//     eventDate: '22 / 04 / 23',
//     eventTitle: 'Welcome to the real world metaverse',
//     id: '11',
//     shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore xriet dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
//
// }, {
//     eventImg: 'https://fs39.fex.net/preview/4135803823/0x0',
//         eventPlace: '950 Fulton St, Brooklyn, NY 11238, USA',
//         eventDate: '04 / 05 / 23',
//         eventTitle: 'The world without limits. I\'ts Unisphere.',
//         id: '12'
// }, {
//     eventImg: 'https://fs39.fex.net/preview/4135803810/0x0',
//         eventPlace: '950 Fulton St, Brooklyn, NY 11238, USA',
//         eventDate: '04 / 05 / 23',
//         eventTitle: 'The world without limits. I\'ts Unisphere.',
//         id: '13'
// }, {
//     eventImg: 'https://fs39.fex.net/preview/4135803800/0x0',
//         eventPlace: '950 Fulton St, Brooklyn, NY 11238, USA',
//         eventDate: '04 / 05 / 23',
//         eventTitle: 'The world without limits. I\'ts Unisphere.',
//         id: '14'
// }, {
//     eventImg: 'https://fs39.fex.net/preview/4135803812/0x0',
//         eventPlace: '950 Fulton St, Brooklyn, NY 11238, USA',
//         eventDate: '04 / 05 / 23',
//         eventTitle: 'The world without limits. I\'ts Unisphere.',
//         id: '15'
// }, {
//     eventImg: 'https://fs39.fex.net/preview/4135803823/0x0',
//         eventPlace: '950 Fulton St, Brooklyn, NY 11238, USA',
//         eventDate: '04 / 05 / 23',
//         eventTitle: 'The world without limits. I\'ts Unisphere.',
//         id: '16'
// }

