import React from 'react';
import * as styles from "./styles.module.scss";
import UCircle from "../ForInvestorCircles/UCircle";
import MainFigure from "./MainFigure";
import RhombusBack from "./RhombusBack";


const RhombusFigure = () => {
    return (
        <div className={styles.rhombusFigure_container}>
            <div className={styles.mainFigure_block}>
                <MainFigure/>
            </div>
            <div className={styles.rhombusBack_block}>
                <RhombusBack/>
            </div>
        </div>
    );
};

export default RhombusFigure;