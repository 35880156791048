import React, {useEffect} from 'react';

import * as styles from "./styles.module.scss";
import Parallax from "parallax-js";

import planet from "../img/planet.png";
import planetLine from "../img/planetLine.png"
import dashed from "../../dashedImg/mainBlue.png";

const ForInvestorPlanet = () => {
    useEffect(() => {
        const planet_scene = document.getElementById("planet_scene");
        new Parallax(planet_scene);
    }, []);

    return (
        <div className={styles.planet_block}>
            <img className={styles.planet_line} src={planetLine} alt="line"/>
            <div className={styles.planet_container}>
                <img src={planet} alt=""/>
                <div id='planet_scene' className={styles.polygon}>
                    <img className={styles.dashed} data-depth={2} src={dashed} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default ForInvestorPlanet;