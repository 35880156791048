import React, {useEffect} from 'react';

import * as styles from "./styles.module.scss";
import Parallax from "parallax-js";

import roof from "../img/roof.png"
import dashed from "../../dashedImg/mainBlue.png"

const Roof = () => {
    useEffect(() => {
        const roof_scene = document.getElementById("roof_scene");
        new Parallax(roof_scene);
    }, []);

    return (
        <div className={styles.roof_container}>
            <img src={roof} alt=""/>
            <div id='roof_scene' className={styles.polygon}>
                <img className={styles.dashed} data-depth={2} src={dashed} alt=""/>
            </div>
        </div>
    );
};

export default Roof;
