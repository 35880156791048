import React, {useEffect} from 'react';

import * as styles from "./styles.module.scss";
import Parallax from "parallax-js";

import palm from "../img/palm.png"
import dashed from "../../dashedImg/mainBlue.png"

const Palm = () => {
    useEffect(() => {
        const palm_scene = document.getElementById("palm_scene");
        new Parallax(palm_scene);
    }, []);

    return (
        <div className={styles.palm_block}>
            <div className={styles.palm_container}>
                <img src={palm} alt=""/>
                <div id='palm_scene' className={styles.polygon}>
                    <img className={styles.dashed} data-depth={2} src={dashed} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default Palm;